import React from 'react';

function Img({ src, ...rest }) {
  const imgSrc = (() => {
    try {
      if (src.includes('http')) return src;
      return require('assets/images/' + src);
    } catch {
      return require('assets/images/coins/Coin-unknown.png');
    }
  })();

  return <img src={imgSrc} {...rest} />;
}

export default Img;
