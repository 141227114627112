import App from 'components';
import Home from 'components/pages/home';
import Leaderboard from 'components/pages/leaderboard';
import NotFound from 'components/pages/not-found';

const routes = [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/token/:coin',
        exact: true,
        component: Leaderboard,
      },
      {
        path: '/*',
        component: NotFound,
      },
    ],
  },
];

export default routes;
