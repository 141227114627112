import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';


export function useData({ actionCreator, dataGetter, paramsParser, params = {} }) {
  useActionCreator(actionCreator, params, paramsParser);
  return useDataGetter(dataGetter, params, paramsParser);
}

export function useDataParams(params = {}, paramsParser) {
  const dispatch = useDispatch();
  const matchParams = useParams();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (typeof paramsParser === 'function') {
    return useSelector((state) => paramsParser(state, { ...params, ...matchParams, ...query, dispatch }));
  }
  return { ...params, ...matchParams, ...query, dispatch };
}

export function useActionCreator(actionCreator, defaultParams = {}, paramsParser) {
  const dispatch = useDispatch();
  const params = useDataParams(defaultParams, paramsParser);

  useEffect(() => {
    if (typeof actionCreator !== 'function') return;
    if (!params) return;
    dispatch(actionCreator(params));
  }, []);
}

export function useDataGetter(dataGetter, defaultParams = {}, paramsParser) {
  const params = useDataParams(defaultParams, paramsParser);

  let data = null;
  if (typeof dataGetter === 'function') data = useSelector((state) => dataGetter(state, params));
  else if (typeof dataGetter !== 'object') data = undefined;
  else {
    data = Object.entries(dataGetter).reduce((res, [key, selector]) => {
      let newRes = res;
      if (typeof selector !== 'function') newRes[key] = selector;
      else if (key === 'root') newRes = { ...newRes, ...useSelector((state) => selector(state, params)) };
      else newRes[key] = useSelector((state) => selector(state, params));

      return newRes;
    }, {});
  }

  if (!params) return {};
  return data || {};
}
