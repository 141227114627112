import axios from 'axios';
import { AppContext } from 'components';
import { useData } from 'components/hooks/data';
import qs from 'qs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getCurrentToken } from 'store/reducers/wallet';
import { iife } from 'utils/helper';

const API_ENDPOINT = iife(() => {
  return {
    SWINGBY: 'https://pre-staking-swingby.swingby.network',
    BNB: 'https://staking-api.swingby.network',
  };
});

let leaderboardMetadataLoading = false;
let networkFee = 0; // eslint-disable-line

export function useLeaderboardData(symbol, page = 1, limit = 10) {
  const props = useData({
    dataGetter: (state, params) => {
      const data = {};

      const currentToken = getCurrentToken(state, params);
      if (currentToken) data.currentToken = currentToken;

      return data;
    },
    paramsParser: (_, params) => {
      params.token = symbol;
      return params;
    },
  });

  const [data, setData] = useState([]);
  const symbolFull = props.currentToken?.symbolFull;

  useEffect(() => {
    iife(async () => {
      if (!symbolFull) return;
      try {
        const res = await axios.get(
          API_ENDPOINT[symbol] + `/v1/stakes/leaderboard?${qs.stringify({ page, page_size: limit })}`,
        );

        if (!res || !res.data) return;
        setData([res.data.items, res.data.total, res.data.totalStaked]);
      } catch (err) {
        console.error('ERROR: ', err);
      }
    });
  }, [page, limit, symbolFull]);

  return data;
}

export function useLeaderboardMetadata(symbol) {
  const props = useData({
    dataGetter: (state, params) => {
      const data = {};

      const currentToken = getCurrentToken(state, params);
      if (currentToken) data.currentToken = currentToken;

      return data;
    },
    paramsParser: (_, params) => {
      params.token = symbol;
      return params;
    },
  });

  const [data, setData] = useState();
  const context = useContext(AppContext);
  const symbolFull = props.currentToken?.symbolFull;

  useEffect(() => {
    (async () => {
      if (context.metadata?.[symbolFull]) return setData(context.metadata[symbolFull]);
      if (!symbolFull || leaderboardMetadataLoading) return;

      try {
        leaderboardMetadataLoading = true;
        const res = await axios.get(API_ENDPOINT[symbol] + '/v1/chain/asset');
        if (!res || !res.data) return;

        leaderboardMetadataLoading = false;
        setData(res.data);
        context.setContext({ metadata: { ...context.metadata, [symbolFull]: res.data } });
      } catch (err) {
        leaderboardMetadataLoading = false;
        console.error('ERROR: ', err);
      }
    })();
  }, [symbolFull, context.metadata?.[symbolFull]]);

  return data;
}

export function useWeeklyMemo(symbol) {
  const [data, setData] = useState();
  // const [payout, setPayout] = useState();

  const handleFetchWeeklyMemo = useCallback(async () => {
    const res = await axios.get(API_ENDPOINT[symbol] + '/v1/stakes/weekly_memo');
    if (!res || !res.data) return;
    setData(res.data);
  }, []);

  // const handleFetchPayout = useCallback(async () => {
  //   if (!data) return;
  //   const res = await axios.get(API_ENDPOINT[symbol] + `/v1/stakes/generate_payout?memo=${data}`);
  //   if (!res || !res.data) return;
  //   setPayout(Math.floor((res.data.estimatedPayout || 0) * 1000) / 1000);
  // });

  useEffect(() => {
    handleFetchWeeklyMemo();
  }, []);

  // useEffect(() => {
  //   handleFetchPayout();
  // }, [data]);

  return [data];
}

export function setNetworkFee(fee) {
  if (fee) networkFee = fee;
}
