import immerProduce from 'immer';
import { toast } from 'react-toastify';

export function iife(callback) {
  return callback();
}

export function produce(initialState, callback) {
  if (typeof callback !== 'function') return immerProduce(initialState, () => {});
  return immerProduce(initialState, callback);
}

export function copyToClipboard(str, showToast = true) {
  const el = document.createElement('textarea');
  el.value = str;

  // make element outside of viewport
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  // clipboard manipulating
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  showToast && toast('Copied to clipboard');
}

export function debouncer(timer, callback, timeout = 1000) {
  if (timer) clearTimeout(timer);
  return setTimeout(callback, timeout);
}

export const reducer = (accumulator, item) => accumulator + item;
