import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { iife, produce } from 'utils/helper';

function injectParams(url, { params, query } = {}) {
  if (!url) return console.error('Empty url', url, params);
  let parsedUrl = url;

  if (params) {
    Object.keys(params).forEach((key) => {
      parsedUrl = parsedUrl.replace('${' + key + '}', params[key]);
    });
    if (parsedUrl.indexOf('$') > -1) console.error('Missing params for url', parsedUrl, params);
  }
  if (query) {
    parsedUrl = parsedUrl + '?' + qs.stringify(query);
  }

  return parsedUrl;
}

export const initialState = produce({});

export function setPending(state, key, { keep } = {}) {
  if (state[key] && keep) state[key] = { ...state[key], status: 'PENDING' };
  state[key] = { status: 'PENDING' };
}

export function setError(state, key, { keep } = {}) {
  if (state[key] && keep) state[key] = { ...state[key], status: 'ERROR' };
  state[key] = { status: 'ERROR' };
}

export function setData(state, key, data, { keep, refs = [] } = {}) {
  if (state[key] && keep) state[key] = { ...state[key], status: 'SUCCESS', data };
  state[key] = { status: 'SUCCESS', data };
  refs.forEach((ref) => {
    state[ref] = key;
  });
}

export async function callAPI({ method, url, params, query, body, additionalHeaders }) {
  const fetchAPI = iife(() => {
    if (typeof fetch !== 'undefined') return fetch;
    return require('node-fetch');
  });

  const queryUrl = injectParams(url, { params, query });

  const res = await fetchAPI(queryUrl, {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(additionalHeaders || {}),
    },
    body: body && JSON.stringify(body),
  });

  return res;
}

export function createAsyncAction(type, parser = () => {}, postAction = () => {}) {
  const PENDING = createAction(type + '_PENDING');
  const SUCCESS = createAction(type + '_SUCCESS');
  const FAILED = createAction(type + '_FAILED');

  function actionCreator(params) {
    return async (dispatch, getState) => {
      const nParams = await parser({ params, dispatch, getState });

      if (!nParams) return;
      await iife(async () => {
        try {
          if (nParams.error) {
            dispatch(FAILED(nParams));
            if (typeof params.callback === 'function') params.callback({ error: nParams.error });
            return;
          }
          if (!nParams.url || !!nParams.data) {
            dispatch(SUCCESS(nParams));
            if (typeof params.callback === 'function') params.callback({ data: nParams.data });
            return;
          }
          dispatch(PENDING(nParams));

          const raw = await callAPI({
            method: nParams.method, url: nParams.url,
            params: nParams.params, query: nParams.query, body: nParams.body,
            additionalHeaders: nParams.additionalHeaders,
          });
          if (raw.status !== 200) throw raw;

          const res = await raw.json();
          if (typeof params.callback === 'function') params.callback({ data: res.data });
          dispatch(SUCCESS({ ...nParams, data: res.data }));
        } catch (e) {
          console.error('ERROR:', e);
          if (typeof params.callback === 'function') params.callback({ error: e });
          dispatch(FAILED({ ...nParams, error: e }));
        }
      });

      postAction({ params, dispatch, getState });
    };
  }

  return Object.assign(actionCreator, { PENDING, SUCCESS, FAILED });
}
