import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Col, Row, Table } from 'antd';
import 'assets/styles/pages/_leaderboard.scss';
import { useData } from 'components/hooks/data';
import { useDevice } from 'components/hooks/layout';
import { useLeaderboardData } from 'components/hooks/wallet';
import { WalletPreStaking } from 'components/pages/stake';
import Img from 'components/static/img';
import binance from 'libs/binance';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCurrentToken,
  getLockedAmount,
  getSupportedTokens,
  getTokenBalance,
  getTokenUsdRate,
  getTokenYield,
  getWalletAddress,
} from 'store/reducers/wallet';
import { addCommas, capitalizes } from 'utils/format';
import { iife } from 'utils/helper';
import { getCurrentStakedRange } from 'utils/stake';

const BITMAX_ACCOUNT = 'bnb1vz6llu0jtj5mcxn74jysp0c326fc26cx5pg4lz';

// Memo: Copy from LeaderboardSummary function
export function TimelockSummary() {
  const [lockedAmount, setLockedAmount] = useState(0);
  const props = useData({
    dataGetter: (state) => {
      const data = {};

      const supportedTokens = getSupportedTokens(state);
      if (supportedTokens) {
        data.supportedTokens = supportedTokens;
        supportedTokens.forEach((token) => {
          const balance = getTokenBalance(state, { token: token.symbol });
          if (balance) data[`balance.${token.symbol}`] = balance.data;

          const address = getWalletAddress(state);
          if (address) data[`address.${token.symbol}`] = address;

          const yieldData = getTokenYield(state, { token: token.symbol });
          if (yieldData) data[`yield.${token.symbol}`] = yieldData.data;
        });
      }

      return data;
    },
  });

  const device = useDevice();

  const parsedData = props.supportedTokens?.map((item) => {
    const balance = props[`balance.${item.symbol}`];
    const address = props[`address.${item.symbol}`];

    const yields = {
      annual: 0.3,
      annualPerc: '30%',
      weekly: 0.0057,
      weeklyPerc: '0.57%',
    };

    useEffect(() => {
      if (address) {
        (async () => {
          const amount = await getLockedAmount(address);
          amount && setLockedAmount(amount);
        })();
      }
    }, [address]);
    return {
      key: item.symbol,
      symbol: item.symbol,
      balance: item.networkStatus !== 'coming-soon' && balance?.free,
      availableBalance:
        item.networkStatus !== 'coming-soon' && balance?.availableBalance,
      staked: item.networkStatus !== 'coming-soon' && lockedAmount,
      yield: (item.networkStatus !== 'coming-soon' && yields) || {},
      networkStatus: 'Staking (Metanode)',
      coin: [
        <Img
          key="img"
          src={item.image || `coins/Coin-${item.symbol}.png`}
          className="mr-8"
        />,
        `${item.name || item.symbol}`,
      ],
    };
  });

  const columns = [
    {
      title: device === 'mobile' ? 'Coins -Metanode staking' : 'Coins',
      dataIndex: 'coin',
      key: 'coin',
      className: 'text-bold',
      width: '35%',
    },
    {
      title: i18n('network_status'),
      dataIndex: 'networkStatus',
      key: 'networkStatus',
      render: (val) => (
        <span className={cx('text-bold', val)}>{capitalizes(val)}</span>
      ),
    },
    {
      title: i18n('available_balance'),
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      className: 'text-bold',
      render: (val) => (val ? addCommas(val) : '-'),
    },
    {
      title: i18n('stake_balance'),
      dataIndex: 'staked',
      key: 'staked',
      className: 'text-bold',
      render: (val) => (val ? addCommas(val) : '-'),
    },
    {
      title: i18n('interest_rate'),
      dataIndex: 'yield',
      className: 'text-bold',
      render: (val) =>
        val.annualPerc ? (
          <div className={cx('apy-highlight')}>
            <div className="apy-info">
              {i18n('weekly_rate', [
                <span key="weekly_rate" className="apy-info-highlight">
                  {val.weeklyPerc}
                </span>,
              ])}
              <br />
              {i18n('annualised_rate', [
                <span key="annualised_rate" className="apy-info-highlight">
                  {val.annualPerc}
                </span>,
              ])}
            </div>
            <RightOutlined className="icon-view-detail" />
          </div>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <Table
      className="leaderboard-summary"
      columns={device === 'mobile' ? columns.slice(0, 1) : columns}
      dataSource={parsedData}
      pagination={false}
      expandable={{
        expandIconColumnIndex: 0,
        rowExpandable: () => device === 'mobile',
        expandedRowRender: (val) =>
          device === 'mobile' && [
            columns.slice(1).map((column) => (
              <p key={column.key} className="expandable-row">
                <span>{column.title}:</span>{' '}
                {column.render?.(val[column.dataIndex])}
              </p>
            )),
          ],
      }}
      onRow={(record) => {
        return {
          onClick: () =>
            window.open('https://timelock.swingby.network/', '_blank'),
        };
      }}
    />
  );
}

export function LeaderboardSummary() {
  const props = useData({
    dataGetter: (state) => {
      const data = {};

      const supportedTokens = getSupportedTokens(state);
      if (supportedTokens) {
        data.supportedTokens = supportedTokens;
        supportedTokens.forEach((token) => {
          const balance = getTokenBalance(state, { token: token.symbol });
          if (balance) data[`balance.${token.symbol}`] = balance.data;

          const yieldData = getTokenYield(state, { token: token.symbol });
          if (yieldData) data[`yield.${token.symbol}`] = yieldData.data;
        });
      }

      return data;
    },
  });

  const history = useHistory();
  const device = useDevice();
  const [, , totalStaked = 0] = useLeaderboardData();

  const parsedData = props.supportedTokens?.map((item) => {
    const balance = props[`balance.${item.symbol}`];
    const yields = iife(() => {
      if (props[`yield.${item.symbol}`]) return props[`yield.${item.symbol}`];
      if (item.symbol !== 'BNB') return;
      return getCurrentStakedRange(totalStaked);
    });

    return {
      key: item.symbol,
      symbol: item.symbol,
      balance: item.networkStatus !== 'coming-soon' && balance?.free,
      availableBalance:
        item.networkStatus !== 'coming-soon' && balance?.availableBalance,
      staked: item.networkStatus !== 'coming-soon' && balance?.staked,
      yield: (item.networkStatus !== 'coming-soon' && yields) || {},
      networkStatus: item.networkStatus,
      coin: [
        <Img
          key="img"
          src={item.image || `coins/Coin-${item.symbol}.png`}
          className="mr-8"
        />,
        `${item.name || item.symbol}`,
      ],
    };
  });

  const columns = [
    {
      title: device === 'mobile' ? 'Coins -Pre staking' : 'Coins',
      dataIndex: 'coin',
      key: 'coin',
      className: 'text-bold',
      width: '35%',
    },
    {
      title: i18n('network_status'),
      dataIndex: 'networkStatus',
      key: 'networkStatus',
      render: (val) => (
        <span className={cx('text-bold', val)}>{capitalizes(val)}</span>
      ),
    },
    {
      title: i18n('available_balance'),
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      className: 'text-bold',
      render: (val) => (val ? addCommas(val) : '-'),
    },
    {
      title: i18n('stake_balance'),
      dataIndex: 'staked',
      key: 'staked',
      className: 'text-bold',
      render: (val) => (val ? addCommas(val) : '-'),
    },
    {
      title: i18n('interest_rate'),
      dataIndex: 'yield',
      className: 'text-bold',
      render: (val) =>
        val.annualPerc ? (
          <div className={cx('apy-highlight')}>
            <div className="apy-info">
              {i18n('weekly_rate', [
                <span key="weekly_rate" className="apy-info-highlight">
                  {val.weeklyPerc}
                </span>,
              ])}
              <br />
              {i18n('annualised_rate', [
                <span key="annualised_rate" className="apy-info-highlight">
                  {val.annualPerc}
                </span>,
              ])}
            </div>
            <RightOutlined className="icon-view-detail" />
          </div>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <Table
      className="leaderboard-summary"
      columns={device === 'mobile' ? columns.slice(0, 1) : columns}
      dataSource={parsedData}
      pagination={false}
      expandable={{
        expandIconColumnIndex: 0,
        rowExpandable: () => device === 'mobile',
        expandedRowRender: (val) =>
          device === 'mobile' && [
            columns.slice(1).map((column) => (
              <p key={column.key} className="expandable-row">
                <span>{column.title}:</span>{' '}
                {column.render?.(val[column.dataIndex])}
              </p>
            )),
          ],
      }}
      onRow={(record) => {
        return {
          onClick: () => history.push(`token/${record.symbol}`),
        };
      }}
    />
  );
}

export function LeaderboardPanel() {
  const props = useData({
    dataGetter: (state, params) => {
      let data = {};

      const currentToken = getCurrentToken(state, params);
      if (currentToken) data.currentToken = currentToken;

      const balance = getTokenBalance(state, params);
      if (balance) data = { ...data, ...balance };

      const yieldData = getTokenYield(state, params);
      if (yieldData) data.yield = yieldData.data;

      return data;
    },
    paramsParser: (_, params) => {
      params.token = params.coin;
      return params;
    },
  });

  const [, , totalStaked = 0] = useLeaderboardData();
  const history = useHistory();
  if (!props.currentToken) {
    history.push('/');
    return null;
  }

  const { data, currentToken } = props;
  const yields = iife(() => {
    if (props.yield) return props.yield;
    if (currentToken.symbol !== 'BNB') return;
    return getCurrentStakedRange(totalStaked);
  });

  return (
    <Card
      className="leaderboard-panel"
      title={
        <>
          <a className="btn-back mb-16" onClick={() => history.push('/')}>
            <LeftOutlined /> {i18n('back')}
          </a>
          <br />
          {!props.balance && <p>{i18n('set_address_first')}</p>}
          <Img
            src={currentToken.image || `coins/Coin-${currentToken.symbol}.png`}
            className="heading-img"
          />
          {currentToken.name || currentToken.symbol}
        </>
      }>
      {currentToken.networkStatus !== 'coming-soon' && yields && (
        <div className="panel-item">
          {i18n('interest_rate')}
          <div className="apy-info">
            <div className="apy-info-item">
              {i18n('weekly_rate', [
                <span key="weekly_rate" className="apy-rate">
                  {yields?.weeklyPerc ?? 0}
                </span>,
              ])}
            </div>
            <div className="apy-info-item">
              {i18n('annualised_rate', [
                <span key="annualised_rate" className="apy-rate">
                  {yields?.annualPerc ?? 0}
                </span>,
              ])}
            </div>
          </div>
          <small>{i18n('yield_description')}</small>
        </div>
      )}
      <div className="panel-item">
        {i18n('network_status')}{' '}
        <div className={cx('pull-right text-bold', currentToken.networkStatus)}>
          {capitalizes(currentToken.networkStatus)}
        </div>
      </div>
      <div className="panel-item">
        {i18n('available_balance')}
        <div className="pull-right">
          {(currentToken.networkStatus !== 'coming-soon' &&
            addCommas(data?.availableBalance)) ||
            '-'}
        </div>
      </div>
      <div className="panel-item">
        {i18n('stake_balance')}
        <div className="pull-right">
          {(currentToken.networkStatus !== 'coming-soon' &&
            addCommas(data?.staked)) ||
            '-'}
        </div>
      </div>
      <div className="panel-item">
        {i18n('minimum_amount', [
          <div key="minimum_amount" className="pull-right">
            -
          </div>,
        ])}
      </div>
      <div className="panel-item">
        {i18n('lock_time', [
          <div key="lock_time" className="pull-right">
            -
          </div>,
        ])}
      </div>

      <WalletPreStaking
        symbol={currentToken.symbol}
        networkStatus={currentToken.networkStatus}
      />
    </Card>
  );
}

export function LeaderboardHeading() {
  const { data } = useData({
    dataGetter: getTokenUsdRate,
    paramsParser: (_, params) => {
      params.token = params.coin;
      return params;
    },
  });

  const params = useParams();
  const [, total = 0, totalStaked = 0] = useLeaderboardData(params.coin);

  return (
    <div>
      <div className="row-dao-announcement">
        <p>{i18n('stakeOnDao')} </p>
        <ul>
          <li>
            <a
              href="https://swingby.substack.com/p/go-far-go-together-with-swingby-dao"
              className="dao-link"
              target="_blank"
              rel="noopener noreferrer">
              {i18n('announcement')}
            </a>
          </li>
          <li>
            <a
              href="https://skybridge-docs.swingby.network/swingby-dao/tutorials/bridge-swingby-to-erc-20"
              className="dao-link"
              target="_blank"
              rel="noopener noreferrer">
              {i18n('howToBridge')}
            </a>
          </li>
        </ul>
      </div>
      <div className="leaderboard-heading">
        <div className="heading-item">
          <small>{i18n('total_staker')}</small>
          <div className="heading-number">{addCommas(total)}</div>
        </div>
        <div className="heading-item">
          <small>{i18n('total_stake')}</small>
          <div className="heading-number">
            {addCommas(totalStaked, 2)} {params.coin}
            {data > 0 && ` ($${addCommas(data * totalStaked, 2)})`}
          </div>
        </div>
      </div>
    </div>
  );
}

export function LeaderboardTable({ size = 10 }) {
  const params = useParams();
  const [page, setPage] = useState(1);
  const offset = size * (page - 1);

  const [data = [], total = 0, totalStaked = 0] = useLeaderboardData(
    params.coin,
    page,
    size
  );

  let hasFoundBitMaxAccount = false;
  const parsedData = data.map((item, i) => {
    let ranking = i + 1 + offset;
    if (hasFoundBitMaxAccount) ranking--;
    else if (item.address === BITMAX_ACCOUNT) {
      hasFoundBitMaxAccount = true;
      ranking = 'BITMAX';
    }

    return {
      key: ranking,
      ranking,
      address: item.address,
      shares: (totalStaked && (item.stake / totalStaked) * 100) || 0,
      stake: item.stake,
    };
  });
  const columns = [
    {
      title: i18n('rank'),
      className: 'text-center',
      dataIndex: 'ranking',
      key: 'ranking',
      render: (val) =>
        iife(() => {
          if (val === 'BITMAX')
            return (
              <a className="ranking-bitmax" title="Bitmax">
                <Img src="bitmax.png" />
              </a>
            );
          if (val <= 3) {
            return (
              <>
                <div className="ranking">{val}</div>
                <Img src={`ranking-${val}.png`} />
              </>
            );
          }
          return val;
        }),
    },
    {
      title: i18n('address'),
      dataIndex: 'address',
      key: 'address',
      render: (val) => (
        <a className="address" href={binance.exploreURL(val)}>
          {val}
        </a>
      ),
    },
    {
      title: i18n('shares'),
      dataIndex: 'shares',
      key: 'shares',
      render: (val) => addCommas(val, 2) + '%',
    },
    {
      title: i18n('staking'),
      dataIndex: 'stake',
      key: 'stake',
      render: (val) => addCommas(val),
    },
  ];

  const handleShowTotal = (_, range) => `${range.join('-')} of ${total}`;
  const onChangePage = useCallback(
    (pagination) => setPage(pagination.current),
    []
  );

  return (
    <Table
      title={() => i18n('top_stakers')}
      className="leaderboard-table"
      columns={columns}
      rowKey="address"
      dataSource={parsedData}
      pagination={{
        total,
        showTotal: handleShowTotal,
        currentPage: page,
        pageSize: size,
        position: 'both',
        size: 'small',
      }}
      onChange={onChangePage}
    />
  );
}

export default function Leaderboard() {
  const device = useDevice();
  return (
    <div className="leaderboard-page">
      <Row type="flex" justify="center">
        <Col md={22} lg={20} xl={18}>
          <Row gutter={device === 'mobile' ? 0 : 32}>
            <Col lg={8} xxl={6}>
              <LeaderboardPanel />
            </Col>
            <Col lg={16} xxl={18}>
              <LeaderboardHeading />
              <LeaderboardTable size={20} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
