import enUS from './en_US';
import zhCN from './zh_CN';

const lang = {
  en_US: {
    name: 'English',
    data: enUS,
  },
  zh_CN: {
    name: '中文',
    data: zhCN,
  },
};

export default lang;
