import 'assets/styles/components/_spinner.scss';
import React from 'react';

// const Plane = () => <div className="sk-plane" />;
// const Chase = () => (
//   <div className="sk-chase">
//     <div className="sk-chase-dot" />
//     <div className="sk-chase-dot" />
//     <div className="sk-chase-dot" />
//     <div className="sk-chase-dot" />
//     <div className="sk-chase-dot" />
//     <div className="sk-chase-dot" />
//   </div>
// );
const Bounce = () => (
  <div className="sk-bounce">
    <div className="sk-bounce-dot" />
    <div className="sk-bounce-dot" />
  </div>
);
const Wave = () => (
  <div className="sk-wave">
    <div className="sk-wave-rect" />
    <div className="sk-wave-rect" />
    <div className="sk-wave-rect" />
    <div className="sk-wave-rect" />
    <div className="sk-wave-rect" />
  </div>
);
const Pulse = () => <div className="sk-pulse" />;
const Flow = () => (
  <div className="sk-flow">
    <div className="sk-flow-dot" />
    <div className="sk-flow-dot" />
    <div className="sk-flow-dot" />
  </div>
);
// const Swing = () => (
//   <div className="sk-swing">
//     <div className="sk-swing-dot" />
//     <div className="sk-swing-dot" />
//   </div>
// );
// const Circle = () => (
//   <div className="sk-circle">
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//     <div className="sk-circle-dot" />
//   </div>
// );
// const CircleFade = () => (
//   <div className="sk-circle-fade">
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//     <div className="sk-circle-fade-dot" />
//   </div>
// );
// const Grid = () => (
//   <div className="sk-grid">
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//     <div className="sk-grid-cube" />
//   </div>
// );
// const Fold = () => (
//   <div className="sk-fold">
//     <div className="sk-fold-cube" />
//     <div className="sk-fold-cube" />
//     <div className="sk-fold-cube" />
//     <div className="sk-fold-cube" />
//   </div>
// );
const Wander = () => (
  <div className="sk-wander">
    <div className="sk-wander-cube" />
    <div className="sk-wander-cube" />
    <div className="sk-wander-cube" />
  </div>
);

export default function Spinner({ type, children }) {
  return (
    <div className="spinner-wrapper">
      {(() => {
        if (type === 'bounce') return <Bounce />;
        if (type === 'pulse') return <Pulse />;
        if (type === 'wave') return <Wave />;
        if (type === 'flow') return <Flow />;
        if (type === 'wander') return <Wander />;
        return <div className="lozi" />;
      })()}
      {children}
    </div>
  );
}
