import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'store/reducers';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' ? true : false,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('store/reducers', () => {
    const newRootReducer = require('store/reducers').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
