export default {
  // All
  home_title: '质押操作简单，轻松获得奖励',
  how_to_stake: '如何股权您的令牌',
  find_out: '了解',
  network_status: '网络状态',
  available_balance: '可用余额',
  stake_balance: '权益质押余额',
  available_to_stake: '可权益质押量',
  interest_rate: '利率（估计）',
  weekly_rate: '每周：{0}',
  annualised_rate: '年率：{0}',
  minimum_amount: '最小金额{0}',
  lock_time: '锁定期间',
  back: '后退',
  yield_description: '两种利润都是估计值。实际支出可能高于或低于这估计值',
  total_staker: 'Stakers总数',
  total_stake: '总放样',
  supply_percent: '供应%',
  rank: '级别',
  address: '地址',
  shares: '分配',
  staking: 'Staking',
  top_stakers: '最高Stakers',
  // Staking modal
  minimum_amount_alert: '最低金额',
  amount_exceeded_alert: '金额超过你的余额',
  available: '最大：{0}',
  network_fee: '网络费用{0}',
  monthly_earning: '每月收入{0}',
  estimate_from_current_apy: '从目前的APY估计',
  memo: '备注{0}',
  vote: '投票',
  choose_currency: '选择货币',
  optional: '*可选的',
  currency_name: '货币名称',
  swingby_token: 'Swingby代币',
  amount: '量',
  copy: '复制',
  scan_qr_code: '扫描这个QR码',
  staking_successful: 'Stake成功',
  stake: 'Stake',
  set_address_first: '首先请设置您的地址',
  temporary_not_able: '暂时无法放样',
  confirmation: '确认',
  cancel: '取消',
  close: '关',
  confirm: '确认',
  go_to_trust_wallet: '转到信托钱包',
  stake_modal_tip:
    '您可以通过发送你的硬币到你的钱包的股份。建议在任何时间进入在<span class="text-primary">最高</span>量。您的本金余额将被覆盖的最新跑马圈地TX。',
  amount_ok: '金额是确定',
  // Footer
  home: '首页',
  term_of_use: '使用条款',
  privacy_policy: '隐私政策',
  support: '服务',
  swingby_copyright: 'Swingby Labs. 版权所有',
  choose_language: '选择你的语言',
  // navbar
  input_balance: '请输入您的地址',
  invalid_wallet_address: '这个地址是无效',
  invalid_wallet: '这个地址应该有一些{0}开始放样！',
  reset: '重启',
  set_address: '设置地址',
  calculate_reward: '计算质押奖励 →',
  pre_staking_tutorial: '参见教程视频 →',
};
