import { CaretDownOutlined, GlobalOutlined } from '@ant-design/icons';
import React from 'react';
import cx from 'utils/classnames';
import { getLanguageList, getLocale, getLocaleName, setLocale } from 'utils/i18n';

export default function LanguageSwitcher({ className }) {
  const currentLocale = getLocale();
  const updateLocale = (locale) => {
    if (locale === currentLocale) return;

    setLocale(locale);
    location.reload();
  };

  const languages = getLanguageList();
  if (!languages) return null;

  return (
    <div className={`language-switcher ${className}`}>
      <div className="current">
        <GlobalOutlined style={{ fontSize: 16 }} />
        <span className="ml-8 mr-4">{i18n(getLocaleName(currentLocale))}</span>
        <CaretDownOutlined />
      </div>
      <div className="language-list">
        <div className="language-item disabled">{i18n('choose_language', [], true)}</div>
        {languages.map((language) => (
          <div
            key={language.name}
            className={cx('language-item', language.locale === currentLocale && 'selected')}
            onClick={() => updateLocale(language.locale)}
          >
            {language.name}
          </div>
        ))}
      </div>
    </div>
  );
}
