import LanguageSwitcher from 'components/shared/language/language-switcher';
import Img from 'components/static/img';
import React from 'react';

function Footer() {
  // const [currentNet, setCurrentNet] = useState(localStorage.getItem('currentNet') || 'mainnet');
  // const handleSelect = (e) => {
  //   const value = e.target.value;
  //   setCurrentNet(value);
  //   localStorage.setItem('currentNet', value);
  //   location.reload();
  // };

  return (
    <div className="footer">
      <div className="footer-nav">
        <a href="https://swingby.network/en" className="footer-item" target="_blank" rel="noopener noreferrer">
          {i18n('home')}
        </a>
        <a
          href="https://docs.swingby.network/terms.pdf"
          className="footer-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n('term_of_use')}
        </a>
        <a
          href="https://docs.swingby.network/privacy-policy.pdf"
          className="footer-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n('privacy_policy')}
        </a>
        <a href="mailto:support@swingby.network" className="footer-item" target="_blank" rel="noopener noreferrer">
          {i18n('support')}
        </a>
        <LanguageSwitcher />
      </div>

      <div className="footer-content">
        <Img className="logo" src="logo.png" />
        <div className="social-list">
          <a href="https://twitter.com/swingbyprotocol" target="_blank" rel="noopener noreferrer">
            <Img src="social/twitter.svg" className="social-item" />
          </a>
          <a href="https://swingby.medium.com/" target="_blank" rel="noopener noreferrer">
            <Img src="social/medium.svg" className="social-item" />
          </a>
          <a href="https://github.com/SwingbyProtocol/" target="_blank" rel="noopener noreferrer">
            <Img src="social/github.svg" className="social-item" />
          </a>
          <a href="https://t.me/swingby" target="_blank" rel="noopener noreferrer">
            <Img src="social/telegram.svg" className="social-item" />
          </a>
        </div>
        {/* <select className="netselect" value={currentNet} onChange={handleSelect}>
          <option value="mainnet">Mainnet</option>
          <option value="testnet">Testnet</option>
        </select> */}
      </div>

      <div className="copyright">© 2018-{new Date().getFullYear()} {i18n('swingby_copyright')}</div>
    </div>
  );
}

export default Footer;
