import { Form } from '@ant-design/compatible';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal } from 'antd';
import { useData } from 'components/hooks/data';
import { useDevice } from 'components/hooks/layout';
import { setNetworkFee as saveNetworkFee, useWeeklyMemo } from 'components/hooks/wallet';
import Img from 'components/static/img';
import binance from 'libs/binance';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getCurrentToken, getTokenBalance, getTokenYield, getWalletAddress } from 'store/reducers/wallet';
import cx from 'utils/classnames';
import { addCommas, removeCommas } from 'utils/format';
import { copyToClipboard, iife } from 'utils/helper';
import i18n from 'utils/i18n';

let wsConnection = null;

const MINIMUM_AMOUNT = {
  BNB: 10,
  SWINGBY: 50,
};

function StepInitial({ step, form, currentToken, balance, networkFee, mode, payout, memoVote, walletAddress, confirmAddress, setConfirmAddress }) {
  const inputRef = useRef();

  const [availableBalance, minimumAmount] = iife(() => {
    if (currentToken.symbol === 'BNB') {
      return [removeCommas(addCommas(balance.free - networkFee)), MINIMUM_AMOUNT['BNB']];
    }
    return [removeCommas(addCommas(balance.free)), MINIMUM_AMOUNT[currentToken.symbol] || MINIMUM_AMOUNT['BNB']];
  });
  const isValidAmount = minimumAmount <= form.getFieldValue('amount') && form.getFieldValue('amount') <= availableBalance;

  const handleFillMaxAmount = useCallback(() => {
    form.setFieldsValue({ amount: availableBalance });
  }, [availableBalance]);

  useEffect(() => {
    if (!mode) return;
    inputRef.current.focus();
  }, [mode]);

  if (step !== 'INITIAL') return null;
  return (
    <Form className="stake-withdraw-form">
      <div className="form-info">
        {i18n('stake_modal_tip', [], true)}
      </div>

      <Form.Item
        label={i18n('amount')}
        help={isValidAmount ? <div className="text-green"><CheckCircleFilled /> {i18n('amount_ok')}</div> : undefined}
      >
        {form.getFieldDecorator('amount', {
          validateFirst: true,
          validateTrigger: ['onFocus', 'onChange'],
          normalize: (val) => val && String(val).replace(/[^0-9.]/g, ''),
          rules: [
            {
              required: true,
              validator: (_, value) => +value >= minimumAmount,
              message: (
                <div className="text-red">
                  <CloseCircleOutlined /> {i18n('minimum_amount_alert', [minimumAmount])}
                </div>
              ),
            },
            {
              required: true,
              validator: (_, value) => +value <= availableBalance,
              message: (
                <div className="text-red">
                  <CloseCircleOutlined /> {i18n('amount_exceeded_alert')}
                </div>
              ),
            },
          ],
        })(
          <Input
            ref={inputRef}
            className="stake-value"
            addonAfter={
              <span className="pointer" onClick={handleFillMaxAmount}>
                {i18n('available', [
                  <span key="available" className="text-green">{availableBalance}</span>,
                ])}
              </span>
            }
          />,
        )}
      </Form.Item>

      <div className="form-info">
        {i18n('network_fee', [
          <div key="network_fee" className="pull-right">{networkFee} BNB</div>,
        ])}
      </div>
      {mode === 'stake' && currentToken.symbol === 'SWINGBY' && (
        <div className="form-info">
          {i18n('monthly_earning', [
            <div key="monthly_earning" className="pull-right">{addCommas(payout)} {currentToken.symbol}</div>,
          ])}
          <br />
          <small>{i18n('estimate_from_current_apy')}</small>
        </div>
      )}
      <div className="form-info">
        {i18n('memo', [
          <div key="memo" className="pull-right"> {memoVote}</div>,
        ])}
      </div>
      {/* <div className="form-info">
        {i18n('vote')}
        <select className="pull-right" value={vote} onChange={({ target: { value } }) => setVote(value)}>
          <option value="">{i18n('choose_currency')}</option>
          <option value="BTC">BTC</option>
        </select>
        <br />
        <small>{i18n('optional')}</small>
      </div> */}
      <div className="form-info text-center">
        <div className="text-primary">{walletAddress}</div>
        <Checkbox
          checked={confirmAddress}
          onChange={(e) => setConfirmAddress(e.target.checked)}>
          {i18n('confirm_address')}
        </Checkbox>
      </div>
    </Form>
  );
}

function StepConfirm({ step, form, device, networkFee, walletAddress, memoVote, payout, currentToken }) {
  const amount = form.getFieldValue('amount');
  const amountRef = useRef();
  const handleAmountClick = useCallback(() => {
    amountRef.current.select();
  }, []);

  const goToTrustWallet = useCallback(() => {
    const tokenId = currentToken.symbol === 'SWINGBY' ? `&token_id=${currentToken.symbolFull}` : '';
    const universalLink = `https://link.trustwallet.com/send?coin=714${tokenId}&address=${walletAddress}&amount=${amount}&memo=${memoVote}`;

    copyToClipboard(universalLink, false);
    window.location.href = universalLink;
  }, [amount]);

  if (!['CONFIRM', 'WAITING'].includes(step)) return null;

  return (
    <div className="stake-withdraw-form">
      {/* {step === 'WAITING' && <LoadingAnimation />} */}
      <div className="form-info flex">
        {i18n('currency_name')}{' '}
        <div className="pull-right">
          <Img src={currentToken.image} width="30" className="mr-16" />
          {currentToken.name}
        </div>
      </div>
      <div className="form-info">
        {i18n('amount')}
        <input
          ref={amountRef}
          className="pull-right selectable"
          value={form.getFieldValue('amount') || ''}
          readOnly
          onClick={handleAmountClick}
        />
      </div>
      <div className="form-info form-qr">
        {i18n('address')}
        <span className="qr-text">{walletAddress}</span>
        <div className="action">
          <a className="btn btn-action">
            <Img src="https://stake.swingby.network/qr-code.png" />
            <div className="qr-popup">
              <QRCode className="qr-code" size={120} value={walletAddress} />
            </div>
          </a>
          <a className="btn btn-action" onClick={() => copyToClipboard(walletAddress)}>
            {i18n('copy')}
          </a>
        </div>
      </div>
      <div className="form-info form-qr">
        {i18n('memo')}
        <span className="qr-text">{memoVote}</span>
        <div className="action">
          <a className="btn btn-action">
            <Img src="https://stake.swingby.network/qr-code.png" />
            <div className="qr-popup">
              <QRCode className="qr-code" size={120} value={memoVote} />
            </div>
          </a>
          <a className="btn btn-action" onClick={() => copyToClipboard(memoVote)}>
            {i18n('copy')}
          </a>
        </div>
      </div>

      <div className="form-info">
        {i18n('network_fee', [
          <div key="network_fee" className="pull-right">{networkFee} BNB</div>,
        ])}
      </div>
      {currentToken.symbol === 'SWINGBY' && (
        <div className="form-info">
          {i18n('monthly_earning', [
            <div key="monthly_earning" className="pull-right text-green">{addCommas(payout)} {currentToken.symbol}</div>,
          ])}
          <br />
          <small>{i18n('estimate_from_current_apy')}</small>
        </div>
      )}
      {device === 'mobile' && (
        <div className="form-info text-center">
          <a onClick={goToTrustWallet}>{i18n('go_to_trust_wallet')}</a>
        </div>
      )}
    </div>
  );
}

function StepScanQr({ step, title, value }) {
  if (!['QR_ADDRESS', 'QR_MEMO'].includes(step)) return null;

  return (
    <div className="stake-withdraw-form text-center withdraw-form-select">
      <div className="form-info">
        <QRCode className="qr-code" value={value} />
        <p>{title}:</p>

        <p className="text-primary" onClick={() => copyToClipboard(value)}>
          {value}
        </p>
        <p>{i18n('scan_qr_code')}</p>
      </div>
    </div>
  );
}

function StepSuccess({ step }) {
  if (step !== 'SUCCESS') return null;

  return (
    <div className="stake-withdraw-form text-center">
      <div className="form-info">
        <Img src="check.png" width="128px" />
        <div className="title">{i18n('done')}</div>
        <div className="text-red content my-16">{i18n('done_notice')}</div>
        <div className="content">{i18n('done_message', [], true)}</div>
      </div>
    </div>
  );
}

export const WalletPreStaking = Form.create()(function({ form }) {
  const props = useData({
    dataGetter: (state, params) => {
      const data = {};

      const walletAddress = getWalletAddress(state);
      if (walletAddress) data.walletAddress = walletAddress;

      const currentToken = getCurrentToken(state, params);
      if (currentToken) data.currentToken = currentToken;

      const balance = getTokenBalance(state, params);
      if (balance) data.balance = balance.data;

      const yieldData = getTokenYield(state, params);
      if (yieldData) data.yield = yieldData.data;

      return data;
    },
    paramsParser: (_, params) => {
      params.token = params.coin;
      return params;
    },
  });

  const device = useDevice();
  const [step, setStep] = useState('INITIAL'); // INITIAL | CONFIRM | QR_ADDRESS | QR_MEMO | WAITING | SUCCESS | FAILED
  const [networkFee, setNetworkFee] = useState();
  const [mode, setMode] = useState();
  const [qrResolved, setQrResolved] = useState({});
  const [confirmAddress, setConfirmAddress] = useState(false);

  const [vote, setVote] = useState();
  const [memo] = useWeeklyMemo(props.currentToken?.symbol);
  const memoVote = (memo ?? '').slice(0, 6) + (vote ? `_${vote}` : '');

  const payout = iife(() => {
    const amount = form.getFieldValue('amount') || 0;
    const monthlyYield = (props.yield?.weekly / 7) * 30 || 0;

    return (amount * monthlyYield).toFixed(3);
  });

  const handleSubmit = useCallback(async () => {
    if (!props.walletAddress) return;
    if (step === 'INITIAL') {
      await form.validateFields((err) => {
        if (err) return console.error('ERROR: form validate failed', err);
        setStep('CONFIRM');

        wsConnection = new WebSocket(binance.socketURL);
        wsConnection.onopen = function() {
          wsConnection.send(JSON.stringify({ method: 'subscribe', topic: 'transfers', address: props.walletAddress }));
        };
        wsConnection.onmessage = function(evt) {
          try {
            JSON.parse(evt.data);
            setTimeout(() => setStep('SUCCESS'), 1000);
            wsConnection.close();
          } catch (e) {
            console.error('ERROR: cannot parse transfer data', e);
          }
        };
      });
      return;
    }
  }, [step, mode]);

  const handleCancel = useCallback(() => {
    setMode(null);
    setTimeout(() => {
      setStep('INITIAL');
      setQrResolved({});
      setConfirmAddress(false);
      wsConnection && wsConnection.close();
    }, 500);
  }, [step, mode]);

  const handleGenQR = useCallback(
    (type) => {
      return () => {
        setStep(type);
      };
    },
    [step, mode],
  );

  const handleCloseQR = useCallback(() => {
    const newQrResolved = { ...qrResolved, [step]: true };
    setQrResolved(newQrResolved);
    setStep('CONFIRM');
  }, [step, mode, qrResolved]);

  useEffect(() => {
    // binance.price(symbol).then(setPrice);
    binance.fees().then((res) => {
      const fee = iife(() => {
        const fixFee = res.data.find((item) => item.fixed_fee_params?.msg_type === 'send');
        if (fixFee) return fixFee.fixed_fee_params.fee;

        return res.data.find((item) => item.msg_type === 'tokensFreeze')?.fee;
      });
      setNetworkFee(binance.calculateFee(fee));
      saveNetworkFee(binance.calculateFee(fee));
    });
  }, []);

  return (
    <>
      <a
        className={cx('btn-primary btn-fw lg mt-16', !props.balance && 'not-ready')}
        onClick={() => {
          if (!props.balance) return;
          setMode('stake');
        }}
      >
        {i18n('stake')}
      </a>

      <Modal
        visible={!!mode}
        title={
          <>
            {step === 'INITIAL' && (
              props.currentToken?.symbol === 'SWINGBY' ? (
                <h2 className="text-uppercase">
                  Stake Swingby Token <Img src="coins/Coin-SBY.png" className="ml-8" />
                </h2>
              ) : (
                <h2>
                  <Img src={props.currentToken?.image} className="mr-8" /> {props.currentToken?.name}
                </h2>
              )

            )}
            {step === 'CONFIRM' && <h2>{i18n('confirmation')}</h2>}
            {step === 'WAITING' && <h2>{i18n('confirmation')}</h2>}
          </>
        }
        centered
        onCancel={['INITIAL'].includes(step) ? handleCancel : () => {}}
        wrapClassName={cx('stake-withdraw-modal', device, step.toLowerCase())}
        footer={[
          ['INITIAL', 'CONFIRM'].includes(step) && (
            <Button key="cancel" onClick={handleCancel}>
              {i18n('cancel')}
            </Button>
          ),
          ['QR_ADDRESS', 'QR_MEMO'].includes(step) && (
            <Button key="close" onClick={handleCloseQR}>
              {i18n('close')}
            </Button>
          ),
          ['WAITING', 'SUCCESS', 'FAILED'].includes(step) && (
            <Button key="cancel" onClick={handleCancel}>
              {i18n('close')}
            </Button>
          ),
          ['INITIAL'].includes(step) && (
            <Button key="submit" onClick={handleSubmit} disabled={!confirmAddress}>
              {i18n('confirm')}
            </Button>
          ),
        ]}
      >
        <StepInitial {...{
          step, form, mode, payout, memoVote, vote, setVote,
          currentToken: props.currentToken,
          balance: props.balance, networkFee,
          walletAddress: props.walletAddress, confirmAddress, setConfirmAddress,
        }} />
        <StepConfirm {...{
          step, form, device, networkFee, walletAddress: props.walletAddress, handleGenQR,
          memoVote, qrResolved, payout, currentToken: props.currentToken,
        }} />

        <StepScanQr
          step={step}
          title={step === 'QR_ADDRESS' ? 'Address' : 'Memo'}
          value={step === 'QR_ADDRESS' ? props.walletAddress : memoVote}
        />

        <StepSuccess step={step} />
      </Modal>
    </>
  );
});
