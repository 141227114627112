export function addCommas(str = '0', precision = 8) {
  let nStr = str;
  if (precision) nStr = Math.round(nStr * (10 ** precision)) / (10 ** precision);
  nStr = String(nStr);

  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }

  if (precision) return x1 + x2.slice(0, precision + 1);
  return x1 + x2;
}

export function removeCommas(nStr = '0') {
  if (Number.isInteger(nStr)) return nStr;
  return parseFloat(nStr.replace(/,/g, '').replace(/k/g, '000')) || 0;
}

export function roundNumber(amount, precision = 8) {
  const parts = amount
    .toPrecision(precision)
    .replace(/\.?0+$/, '')
    .split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function capitalizes(str) {
  if (!str) return '';
  return str
    .split(/[\s_]+/)
    .map((v) => v[0] && v[0].toUpperCase() + v.slice(1))
    .join(' ');
}
