import { useData } from 'components/hooks/data';
import Img from 'components/static/img';
import * as cookies from 'cookies-js';
import binance from 'libs/binance';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearWalletBalance, fetchWalletBalance, getTokenBalance, getWalletAddress } from 'store/reducers/wallet';
import i18n from 'utils/i18n';
import { getRoute } from 'utils/routing';

function Navbar() {
  const { match } = getRoute(null, window.location.pathname);
  const token = match?.params?.coin;
  const props = useData({
    dataGetter: (state) => {
      const data = {};

      const walletAddress = getWalletAddress(state);
      if (walletAddress) data.walletAddress = walletAddress;

      // TODO: implement fetch all balance base on supportedToken

      const balance = getTokenBalance(state, { token: token || 'BNB' });
      if (balance) data.balance = balance.data;

      return data;
    },
  });

  const dispatch = useDispatch();
  const inputRef = useRef();
  const handleFocus = useCallback(() => {
    inputRef.current.select();
  }, []);

  const [address, setAddress] = useState('');
  const isValidAddress = address ? binance.checkAddress(address) : true;

  const setWalletAddress = useCallback((savedAddress) => {
    let currentAddress = address;
    if (!address && typeof savedAddress === 'string') currentAddress = savedAddress;
    if (!binance.checkAddress(currentAddress)) return;

    if (props.walletAddress) {
      dispatch(clearWalletBalance());
      setAddress('');
      return;
    }
    dispatch(fetchWalletBalance({ walletAddress: currentAddress }));
  }, [address, props.balance, props.walletAddress]);

  useEffect(() => {
    const savedAddress = cookies.get('currentWalletAddress');
    if (savedAddress) {
      setAddress(savedAddress);
      setWalletAddress(savedAddress);
    }
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-content">
        <Link to="/">
          <Img className="logo" src="logo.png" />
        </Link>

        <div className="wallet-address">
          <input
            ref={inputRef}
            value={address}
            onChange={(e) => setAddress(e.target.value.trim())}
            onFocus={props.balance ? handleFocus : undefined}
            readOnly={!!props.balance}
            placeholder={i18n('input_balance')}
          />
          <a className={cx(props.walletAddress ? 'btn-back' : 'btn-primary')} onClick={setWalletAddress}>
            {i18n(props.walletAddress ? 'reset' : 'set_address')}
          </a>
          {!isValidAddress && (
            <div className="warning-message warning">
              {i18n('invalid_wallet_address', [match?.params?.coin?.toLowerCase()])}
            </div>
          )}
          {isValidAddress && props.walletAddress && !props.balance && match?.params?.coin && (
            <div className="warning-message warning">
              {i18n('invalid_wallet', [match.params.coin.toUpperCase()])}
            </div>
          )}
        </div>
        <a href="https://www.youtube.com/watch?v=zI-iAgtcKXo" className="navigation-link" target="_blank" rel="noopener">
          {i18n('pre_staking_tutorial')}
        </a>

        <div className="flex-1" />
        <a href="https://calculator.swingby.network/" className="navigation-link" target="_blank" rel="noopener">
          {i18n('calculate_reward')}
        </a>
      </div>
    </div>
  );
}

export default Navbar;
