// const usdToSwingby = 0.03;

const stakedRanges = [
  {
    min: 0, max: 5000,
    swingbyPerBnb: 3,
    weeklyPerc: '0.6%',
    annualPerc: '34%',
  },
  {
    min: 5000, max: 10000,
    swingbyPerBnb: 1.5,
    weeklyPerc: '0.3%',
    annualPerc: '16%',
  },
  {
    min: 10000, max: 15000,
    swingbyPerBnb: 1,
    weeklyPerc: '0.2%',
    annualPerc: '10%',
  },
  {
    min: 15000, max: 20000,
    swingbyPerBnb: 2,
    weeklyPerc: '0.4%',
    annualPerc: '21%',
  },
  {
    min: 20000, max: 40000,
    swingbyPerBnb: 1,
    weeklyPerc: '0.2%',
    annualPerc: '10%',
  },
  {
    min: 40000, max: 60000,
    swingbyPerBnb: 0.7,
    weeklyPerc: '0.1%',
    annualPerc: '7%',
  },
  {
    min: 60000, max: 100000,
    swingbyPerBnb: 2.5,
    weeklyPerc: '0.5%',
    annualPerc: '28%',
  },
  {
    min: 100000, max: 200000,
    swingbyPerBnb: 1.3,
    weeklyPerc: '0.2%',
    annualPerc: '13%',
  },
  {
    min: 200000, max: 300000,
    swingbyPerBnb: 0.8,
    weeklyPerc: '0.2%',
    annualPerc: '8%',
  },
  {
    min: 300000, max: 400000,
    swingbyPerBnb: 3.1,
    weeklyPerc: '0.6%',
    annualPerc: '35%',
  },
];

export function getCurrentStakedRange(bnbStaked) {
  return stakedRanges.find((range) => {
    return range.min <= bnbStaked && range.max > bnbStaked;
  });
}
