import 'assets/fonts/D-DIN.ttf';
import 'assets/styles/index.scss';
import 'assets/styles/pages/_index.scss';
import { useDevice } from 'components/hooks/layout';
import Footer from 'components/shared/footer';
import Navbar from 'components/shared/navbar';
import Spinner from 'components/static/spinner';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCurrencyRates, fetchTokensYield } from 'store/reducers/wallet';
import cx from 'utils/classnames';
import i18n from 'utils/i18n';

window.cx = cx;
window.i18n = i18n;

const toastifyOptions = {
  className: 'toast-container',
  position: 'bottom-center',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnVisibilityChange: true,
  pauseOnHover: true,
  newestOnTop: true,
  transition: Zoom,
};

export const AppContext = React.createContext({});

export default function App({ children }) {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const setContext = useCallback(
    (key, value) => {
      if (['string', 'number'].includes(typeof key)) return setState({ ...state, [key]: value });
      return setState({ ...state, ...key });
    },
    [state],
  );

  const device = useDevice();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setTimeout(() => { setFlag(true); }, 500);
    dispatch(fetchCurrencyRates());
    dispatch(fetchTokensYield());
  }, []);

  return (
    <AppContext.Provider value={{ ...state, setContext }}>
      <div className={cx('app', device)}>
        <Navbar />
        <div className="content">{children}</div>
        <Footer />
        <ToastContainer {...toastifyOptions} />
      </div>
      <div className={cx('loading-container', flag && 'hide')}>
        <Spinner type="wave" />
      </div>
    </AppContext.Provider>
  );
}
