import bnbClient from '@binance-chain/javascript-sdk';
import axios from 'axios';

// const NET = localStorage.getItem('currentNet') === 'testnet' ? 'testnet' : 'mainnet';
const NET = 'mainnet';

class Binance {
  constructor() {
    if (NET === 'testnet') {
      this.baseURL = 'https://testnet-dex.binance.org';
      this.explorerBaseURL = 'https://testnet-explorer.binance.org';
      this.socketURL = 'wss://testnet-dex.binance.org/api/ws';
    } else {
      this.baseURL = 'https://dex.binance.org';
      this.explorerBaseURL = 'https://explorer.binance.org';
      this.socketURL = 'wss://dex.binance.org/api/ws';
    }

    this.net = NET;
    console.info('Net:', this.net);

    this.httpClient = axios.create({
      baseURL: this.baseURL + '/api/v1',
      contentType: 'application/json',
    });

    this.bnbClient = new bnbClient(this.baseURL);
    this.bnbClient.chooseNetwork(this.net);
    this.bnbClient.initChain();
    this.bnbTokens = new bnbClient(this.bnbClient).tokens;
  }

  setPrivateKey(privateKey) {
    this.bnbClient.setPrivateKey(privateKey);
    this.bnbClient.chooseNetwork(this.net);
    this.bnbClient.initChain();
  }

  useLedgerSigningDelegate(ledgerApp, preSignCb, postSignCb, errCb, hdPath) {
    return this.bnbClient.useLedgerSigningDelegate(ledgerApp, preSignCb, postSignCb, errCb, hdPath);
  }

  clearPrivateKey() {
    this.bnbClient.privateKey = null;
  }

  getPrefix() {
    return NET === 'testnet' ? 'tbnb' : 'bnb';
  }

  txURL(tx) {
    return this.explorerBaseURL + '/tx/' + tx;
  }

  exploreURL(address) {
    return this.explorerBaseURL + '/address/' + address;
  }

  fees() {
    return this.httpClient.get('/fees');
  }

  currencyRate() {
    return this.httpClient.get('/crypto-currency');
  }

  async price(symbol) {
    const bnb = await axios.get('https://api.cryptonator.com/api/ticker/bnb-usd');
    const coins = await this.httpClient.get('/markets');
    const symbol_data = coins.data.find((s) => {
      return s.base_asset_symbol === symbol;
    });
    return parseFloat(bnb.data.ticker.price) * parseFloat(symbol_data?.list_price || 1);
  }

  // convert fee number into BNB tokens
  calculateFee(x) {
    return x / 100000000;
  }

  getBalances(address) {
    return this.bnbClient.getBalance(address);
  }

  getAccount(address) {
    return this.bnbClient.getAccount(address);
  }

  checkAddress(address) {
    return this.bnbClient.checkAddress(address);
  }

  getMarkets(limit = 1000, offset = 0) {
    return this.bnbClient.getMarkets(limit, offset);
  }

  async multiSend(address, transactions, memo = '') {
    // send coins!
    const result = await this.bnbClient.multiSend(address, transactions, memo);

    // clear private key from memory after each transaction
    this.clearPrivateKey();

    return result;
  }
}

const binance = new Binance();
export default binance;
