import { Col, Row } from 'antd';
import 'assets/styles/pages/_home.scss';
import {
  LeaderboardSummary,
  TimelockSummary,
} from 'components/pages/leaderboard';
import React from 'react';
import i18n from 'utils/i18n';

function Home() {
  return (
    <div className='home-page'>
      <Row type='flex' justify='center'>
        <Col md={22} lg={20} xl={18}>
          <div className='mb-32 text-center'>
            <h1>{i18n('home_title')}</h1>
            <p>
              {i18n('find_out')}{' '}
              <a
                href='https://skybridge-docs.swingby.network/getting-start/how-to-timelock-swingby-tokens'
                className='link'
                target='_blank'
                rel='noopener noreferrer'>
                {i18n('how_to_stake')}
              </a>
            </p>
          </div>
          <LeaderboardSummary />
          {/* Memo: Just add margin between 2 card. Ideally the 2 card becomes 1 card. (by top and bottom row) */}
          <div style={{ marginTop: 50 }} />
          <TimelockSummary />
        </Col>
      </Row>
    </div>
  );
}

export default Home;
