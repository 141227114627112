import cookies from 'cookies-js';
import languages from './language';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const defaultLocale = 'en_US';

export function getLocale() {
  const locale = cookies.get('locale');
  return locale || defaultLocale;
}

export function setLocale(locale) {
  if (!languages[locale]) return;
  cookies.set('locale', locale);
}

export function getLanguageList() {
  return Object.keys(languages).map((locale) => {
    const lang = languages[locale];
    return {
      name: lang.name,
      locale,
    };
  });
}

export function getLocaleName(locale) {
  return languages[locale] && languages[locale].name;
}

export default function i18n(key, params, forceReactElement) {
  if (!key) return '';

  const locale = getLocale();
  if (!locale) return key;
  const lang = languages[locale]?.data;
  if (!lang || !lang[key]) return key;

  // need to check if 'field' contains {s} to replace with 'params'
  const regx = /{([0-9]+)}/g;
  const string = lang[key];
  let isElement = !!forceReactElement;

  let str = string || '';
  if (!Array.isArray(params) || params.length === 0) {
    str = str.replace(regx, '');
  } else {
    let tmp;
    do {
      tmp = regx.exec(string);
      if (!tmp || !tmp[1]) break;
      const idx = parseInt(tmp[1]);
      if (React.isValidElement(params[idx])) {
        str = str.replace(tmp[0], ReactDOMServer.renderToString(params[idx]));
        isElement = true;
      } else str = str.replace(tmp[0], params[idx] || '');
    } while (tmp);
  }

  if (isElement) {
    const lines = str.split('\n');
    if (lines.length === 1) return <span dangerouslySetInnerHTML={{ __html: str }} />;
    return <span dangerouslySetInnerHTML={{ __html: lines.map((line) => `<p>${line}</p>`).join('') }} />;
  }
  return str;
}
