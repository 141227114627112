export default {
  // All
  home_title: 'Simply Stake, Simply Earn Rewards',
  how_to_stake: 'How to stake your tokens',
  find_out: 'Find out',
  stakeOnDao:
    'Pre-staking will use ERC20 SWINGBY tokens with the launch of the DAO. Please follow the instructions to bridge your tokens and stake on the DAO.',
  announcement: 'Announcement',
  howToBridge: 'Find out how to bridge from BEP2 to ERC20',
  network_status: 'Network Status',
  available_balance: 'Available Balance',
  stake_balance: 'Staked Balance',
  available_to_stake: 'Available to stake',
  interest_rate: 'Rewards Rate (Estimated)',
  weekly_rate: 'Weekly: {0}',
  annualised_rate: 'Annualised: {0}',
  minimum_amount: 'Minimum Amount {0}',
  lock_time: 'Lock Time {0}',
  back: 'Back',
  yield_description:
    'Displayed rewards are estimations. The actual payouts may be higher or lower than the stated value.',
  total_staker: 'Total Stakers',
  total_stake: 'Total Staked',
  supply_percent: '% of supply',
  rank: 'Rank',
  address: 'Address',
  shares: 'Shares',
  staking: 'Staking',
  top_stakers: 'Top Stakers',
  // Staking modal
  minimum_amount_alert: 'Minimum Amount is {0}',
  amount_exceeded_alert: 'Amount is exceed your balance',
  available: 'Max: {0}',
  network_fee: 'Network Fee {0}',
  monthly_earning: 'Monthly Earnings {0}',
  estimate_from_current_apy: 'Estimated from current APY',
  memo: 'Memo{0}',
  vote: 'Vote',
  choose_currency: 'Choose currency',
  optional: '* Optional',
  currency_name: 'Currency name',
  swingby_token: 'Swingby Token',
  amount: 'Amount',
  copy: 'Copy',
  scan_qr_code: 'Scan this QR Code',
  stake: 'Stake',
  set_address_first: 'Please set your address first',
  temporary_not_able: 'Temporary not able for staking',
  confirmation: 'Confirmation',
  cancel: 'Cancel',
  close: 'Close',
  confirm: 'Confirm',
  confirm_address: 'Confirmed that the address is my address',
  go_to_trust_wallet: 'Go to Trust Wallet',
  stake_modal_tip:
    'You can stake by sending your coins to your wallet. It is recommended to enter the <span class="text-primary">Max</span> amount at any time. Your stake balance will be overwritten by the latest staking tx.',
  done: 'Done!',
  done_notice:
    '⚠️ It may take some time for your stake tx to appear on the leaderboard',
  done_message:
    'Staking is held weekly.\nYou can stake your coins again next week to continue earning rewards.\nSee you next week!',
  amount_ok: 'Amount is ok',
  // Footer
  home: 'Home',
  term_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  support: 'Support',
  swingby_copyright: 'Swingby Labs. All Rights Reserved.',
  choose_language: 'Choose your <b>Language</b>',
  // navbar
  input_balance: 'Input your address',
  invalid_wallet_address: 'This address is invalid',
  invalid_wallet: 'This address should have some {0} to start Staking!',
  reset: 'Reset',
  set_address: 'Set Address',
  calculate_reward: 'Calculate pre-staking reward →',
  pre_staking_tutorial: 'See the tutorial video →',
};
