import { createAction, createReducer } from '@reduxjs/toolkit';
import * as cookies from 'cookies-js';
import binance from 'libs/binance';
import {
  callAPI,
  createAsyncAction,
  setData,
  setError,
  setPending,
} from 'store/helper';
import { produce, reducer } from 'utils/helper';
import moment from 'moment';
import Axios from 'axios';

const initialState = produce({
  supportedTokens: [
    {
      image: 'coins/Coin-SBY.png',
      symbol: 'SWINGBY',
      symbolFull: 'SWINGBY-888',
      name: 'SWINGBY - Swingby Token',
      networkStatus: 'pre-staking',
    },
    {
      image: 'coins/Coin-SBY.png',
      symbol: 'BNB',
      symbolFull: 'BNB',
      name: 'SWINGBY (BNB Stake)',
      networkStatus: 'pre-staking',
      endedAt: '2020-07-28T16:00:00.000Z',
    },
  ].filter((token) => {
    if (!token.endedAt) return true;
    const now = moment();
    const endedAt = moment(token.endedAt);
    return endedAt.isAfter(now);
  }),
});

const BASE_API = {
  SWINGBY: 'https://pre-staking-swingby.swingby.network/v1',
  BNB: 'https://staking-api.swingby.network/v1',
};

function getBaseAPI(token) {
  return BASE_API[token];
}

export const API = {
  FETCH_STAKED_BALANCE: '/stakes?address=${address}',
  FETCH_YIELD: '/stakes/yield',
  FETCH_LEADERBOARD_DATA: '/stakes/leaderboard',

  FETCH_SWINGBY_USD_RATE:
    'https://api.coingecko.com/api/v3/simple/price?ids=swingby&vs_currencies=usd',
};

export const fetchWalletAddress = createAction('WALLET_ADDRESS_FETCH');
export const clearWalletBalance = createAction('WALLET_BALANCE_CLEAR');

export const fetchWalletBalance = createAsyncAction(
  'WALLET_BALANCE_FETCH',
  async ({ dispatch, getState, params }) => {
    if (!params.walletAddress) return;

    const store = getState();
    const balance = await binance.getBalances(params.walletAddress);

    await Promise.all(
      store.wallet.supportedTokens?.map(async (token) => {
        const baseAPI = getBaseAPI(token.symbol);
        const currentBalance = balance.find(
          (b) => b.symbol === token.symbolFull
        );

        if (currentBalance) {
          const stakedRaw = await callAPI({
            url: baseAPI + API.FETCH_STAKED_BALANCE,
            params: { address: params.walletAddress },
          });
          if (stakedRaw.ok) {
            const stakedRes = await stakedRaw.json();
            currentBalance.staked = stakedRes[0]?.stake || 0;
            currentBalance.availableBalance = Math.max(
              currentBalance.free - currentBalance.staked,
              0
            );

            dispatch(
              fetchWalletBalance.SUCCESS({
                key: token.symbol + '.balance',
                data: currentBalance,
                walletAddress: params.walletAddress,
              })
            );
          }
        }
      })
    );
    dispatch(
      fetchWalletAddress({
        walletAddress: params.walletAddress,
      })
    );
  }
);

export const fetchTokensYield = createAsyncAction(
  'YIELD_FETCH',
  async ({ dispatch, getState }) => {
    const store = getState();
    await Promise.all(
      store.wallet.supportedTokens?.map(async (token) => {
        const baseAPI = getBaseAPI(token.symbol);

        if (token.symbol === 'SWINGBY') {
          const yieldRaw = await callAPI({
            url: baseAPI + API.FETCH_YIELD,
          });
          if (yieldRaw.ok) {
            const yieldRes = await yieldRaw.json();
            dispatch(
              fetchTokensYield.SUCCESS({
                key: token.symbol + '.yield',
                data: yieldRes,
              })
            );
          }
        }
      })
    );
  }
);

export const fetchCurrencyRates = createAsyncAction(
  'CURRENCY_RATES_FETCH',
  async ({ dispatch, getState }) => {
    const store = getState();

    await Promise.all(
      store.wallet.supportedTokens?.map(async (token) => {
        if (token.symbol === 'SWINGBY') {
          const currencyRateRaw = await callAPI({
            url: API.FETCH_SWINGBY_USD_RATE,
          });
          if (currencyRateRaw.ok) {
            const currencyRateRes = await currencyRateRaw.json();
            if (currencyRateRes?.swingby?.usd) {
              dispatch(
                fetchCurrencyRates.SUCCESS({
                  key: token.symbol + '.usdRate',
                  data: currencyRateRes.swingby.usd,
                })
              );
            }
          }
        }
        if (token.symbol === 'BNB') {
          const currencyRateRes = await binance.currencyRate();
          if (currencyRateRes?.data?.BNB_USDT) {
            dispatch(
              fetchCurrencyRates.SUCCESS({
                key: token.symbol + '.usdRate',
                data: currencyRateRes.data.BNB_USDT,
              })
            );
          }
        }
      })
    );
  }
);

// ==================================================================================

export default createReducer(initialState, {
  [fetchWalletBalance.PENDING]: (state, action) => {
    const { payload } = action;
    setPending(state, payload?.key);
  },
  [fetchWalletBalance.FAILED]: (state, action) => {
    const { payload } = action;
    setError(state, payload?.key);
  },
  [fetchWalletBalance.SUCCESS]: (state, action) => {
    const { payload } = action;
    setData(state, payload?.key, payload?.data);
  },
  [fetchWalletAddress]: (state, action) => {
    const { payload } = action;
    state['walletAddress'] = payload?.walletAddress;
    cookies.set('currentWalletAddress', payload?.walletAddress);
  },
  [clearWalletBalance]: (state) => {
    delete state['walletAddress'];
    state.supportedTokens.forEach((token) => {
      delete state[token.symbol + '.balance'];
    });

    cookies.expire('currentWalletAddress');
  },
  [fetchTokensYield.SUCCESS]: (state, action) => {
    const { payload } = action;
    setData(state, payload?.key, payload?.data);
  },
  [fetchCurrencyRates.SUCCESS]: (state, action) => {
    const { payload } = action;
    setData(state, payload?.key, payload?.data);
  },
});

// ==================================================================================

export function getWalletAddress(state) {
  const key = 'walletAddress';
  return state.wallet[key];
}

export function getTokenBalance(state, params) {
  if (!params.token) return;
  const key = params.token + '.balance';
  return state.wallet[key];
}

export function getTokenYield(state, params) {
  if (!params.token) return;
  const key = params.token + '.yield';
  return state.wallet[key];
}

export function getTokenUsdRate(state, params) {
  if (!params.token) return;
  const key = params.token + '.usdRate';
  return state.wallet[key];
}

export function getSupportedTokens(state) {
  return state.wallet.supportedTokens;
}

export function getCurrentToken(state, params) {
  if (!params.token) return;
  return state.wallet.supportedTokens?.find(
    (token) => token.symbol === params.token
  );
}

export const getLockedAmount = async (address) => {
  const baseUrl = 'https://dex.binance.org/api/v1/timelocks';
  const res = await Axios.get(`${baseUrl}/${address}`);
  const data = res.data.filter((tx) => tx.amount[0].symbol === 'SWINGBY-888');

  // Memo: Shows total locked amount
  const lockedAmounts = [];
  data.forEach((tx) => {
    if (tx.amount[0].symbol === 'SWINGBY-888') {
      lockedAmounts.push(Number(tx.amount[0].amount));
    }
  });
  return lockedAmounts.reduce(reducer, 0);
};
