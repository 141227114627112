import { useEffect, useState } from 'react';

const DEVICE = { MOBILE: 'mobile', DESKTOP: 'desktop' };
const BREAKPOINTS = [600];

function getNextDevice() {
  if (typeof window === 'undefined') return DEVICE.MOBILE;
  const viewportWidth = window.innerWidth;

  if (viewportWidth < BREAKPOINTS[0]) return DEVICE.MOBILE;
  return DEVICE.DESKTOP;
}

export function useDevice() {
  if (typeof window === 'undefined') return DEVICE.MOBILE;

  const [device, setDevice] = useState(getNextDevice());
  useEffect(() => {
    const handleResize = () => {
      const nextDevice = getNextDevice();
      if (device !== nextDevice) setDevice(nextDevice);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [device]);

  return device;
}
