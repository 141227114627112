export default function cx(...args) {
  return args
    .reduce((res, arg) => {
      if (!arg) return res;
      if (['string', 'number'].includes(typeof arg)) return `${res} ${arg}`;
      if (Array.isArray(arg) && arg.length > 0) return `${res} ${cx(arg)}`;

      if (typeof arg === 'object') {
        let newRes = res;
        for (const key in arg) {
          if (arg[key]) newRes = `${res} ${key}`;
        }
        return newRes;
      }

      return res;
    }, '')
    .slice(1);
}
